import React from "react"
import styles from "./styles.module.css"
import close_profile_icon  from "../../images/close_profile_icon.svg"
import edit_profile_icon  from "../../images/edit_profile_icon.svg"
import PlanSideBar from "../../shared/planSideBar";
import CurrentPlanInformation from "../../shared/currentPlanInformation";
import { viewPlan, viewCloudAddOns, deleteCloudAddOns, createCloudAddOns } from "../../services/customerInfo";
import { getUser, getToken, isUserAdmin } from "../../services/auth";
import { navigate } from "gatsby";
import FormField from "../../shared/formField";
import ProfileSectionHeading from "../../shared/profileSectionHeading";
import AlertModal from "../../shared/alertModal";
import { System_Type } from "../../common/constants";

class ManageUsers extends React.Component {
    constructor(props) {
        super(props);
        const user = getUser();
        const token = user && user.access_token;
        const isTemp = user && user.id ==='TEMP';

        this.state = {
            plan: null,
            cloudAddOnEmails: [],
            token,
            isEditCloudEmailDisabled: true,
            isTemp,
            regForCloud: false,
            isModalOpen: {
                delete: false,
                add: false,
                error: false
            },
            isLoading: {
                delete: false,
                add: false,
                fetch: true
            },
            user,
            dirty: false,
            deleteEmailSelected: '',
            addEmailSelected: ''
        };

    }

    componentDidMount() {
        this.getPlan();
    }

    getCloudAddOns() {
        viewCloudAddOns(getToken()).then(({error,response}) => {
            if (error.status < 400) {
                var cloudAddOnEmails = [];
                var regForCloud = false;

                if (response && response.length>0) {
                    const user = getUser();
                    const regEmailExists = response.findIndex((item) => item.EmailAddr===user.RegEmail);
                    if (this.state.plan.SystemType === System_Type.DESKTOP) {
                        regForCloud = regEmailExists;
                    } else {
                        regForCloud = true;
                    }

                    for (var i=0;i<this.state.plan.NumCloudUsers;i++) {
                        if (i < response.length) {
                            var item = response[i];
                            cloudAddOnEmails.push({email:item.EmailAddr,disabled:true,isAdmin:item.IsAdmin});
                        } else {
                            cloudAddOnEmails.push({email: "", disabled: false});
                        }
                    }
                }

                // var cloudAddOnEmails = [...this.state.cloudAddOnEmails];
                // response.forEach((item,idx) => {
                //     cloudAddOnEmails[idx] = {email:item.EmailAddr,disabled:true,isAdmin:item.IsAdmin};
                // });

                this.setState(
                    {
                        cloudAddOnEmails,
                        dirty:false,
                        isEditCloudEmailDisabled:true,
                        isLoading: {
                            add: false,
                            delete: false,
                            fetch: false
                        },
                        regForCloud,
                        isModalOpen: {
                            ...this.state.isModalOpen,
                            add: false,
                            delete: false,
                        }
                    });
            }
        })
    }

    getPlan = () => {
        return viewPlan(getToken()).then(({response,error}) => {
            var isCloud = false;
            if (error.status>=400) {
                console.log('ERROR',error);
                isCloud = true;
            } else {
                console.log(response,'getPlanResponse');
                if (response.SystemType == System_Type.DESKTOP) {
                    response.NumCloudUsers = response.NumCloudUsers + (response.SystemType === System_Type.DESKTOP ? 1 : 0);
                    this.setState({plan: response, isCloud: false});
                } else {
                    isCloud = true;
                    this.setState({isCloud: true, isAdvisor: response.ProductId > 302})
                }
            }
            return isCloud;
        }).then((isCloud) => {
            if (!isCloud) {
                this.getCloudAddOns();
            }
        });
    }

    onActionClick = (actionValid) => {
        if (actionValid) {
            navigate('/account/order/product');
        }
    }

    onTryClick = (productId) => {
        navigate('/account/order/product',{state: {productId}});
    }

    toggleModal = (type) => {
        this.setState(
            {
                isModalOpen: {
                    ...this.state.isModalOpen,
                    [type]: !this.state.isModalOpen[type]
                }
            }
        )
    }

    onDeleteEmail = (email) => {
        this.toggleLoading('delete');
        deleteCloudAddOns(getToken(),email).then(({error,response}) => {
            if (error.status<400) {
                this.setState({dirty: true, isEditCloudEmailDisabled: true});
                this.getPlan();
                this.toggleLoading('delete');
                this.toggleModal('delete');
            }
        })
    }

    onAddEmail = (email) => {
        this.toggleLoading('add');
        createCloudAddOns(getToken(),email).then(({error,response}) => {
            if (error.status<400) {
                this.setState({dirty: true, isEditCloudEmailDisabled: true});
                this.getPlan();
            } else if (error.status === 400) {
                this.setState({
                    dirty: true,
                    isModalOpen: {
                        ...this.state.isModalOpen,
                        add: false,
                        error: true
                    }
                });
                this.getCloudAddOns();
            }
        })
    }

    toggleLoading = (mode) => {
        this.setState({
            isLoading: {
                ...this.state.isLoading,
                [mode]: !this.state.isLoading[mode]
            }
        });
    }

    onEditCloudAddOnToggle = () => {
        this.setState({
            isEditCloudEmailDisabled: !this.state.isEditCloudEmailDisabled
        });

        if (!this.state.isEditCloudEmailDisabled) {
            this.setState({dirty: true});
            this.getPlan();
        }

    }

    updateCloudEmail = (e,idx) => {
        var cloudAddOnEmails = [...this.state.cloudAddOnEmails];
        cloudAddOnEmails[idx] = {...cloudAddOnEmails[idx],email:e.target.value};
        this.setState({cloudAddOnEmails});
    }

    editEmail = (item) => {
        if(item.disabled) {
            this.setState({deleteEmailSelected: item.email});
            this.toggleModal('delete');
        } else {
            this.setState({addEmailSelected: item.email});
            this.toggleModal('add');
        }
    }

    render() {
        const isAdmin = isUserAdmin(this.state.user.access_rights);
        const isEditCloudEmailDisabled = this.state.isEditCloudEmailDisabled;
        const icon = isAdmin ? (isEditCloudEmailDisabled ? edit_profile_icon : close_profile_icon) : null;
        const iconText = isAdmin ? (isEditCloudEmailDisabled ? 'Add/Delete Users' : 'Cancel') : null;
        const useTextIcon = isAdmin ? (isEditCloudEmailDisabled ? true : false) : false;
        const isCloud = this.state.isCloud;
        const sectionHeadingText = isCloud ? 'Manage your users' : 'Manage your Cloud users';
        return (
        <>
            <AlertModal isOpen={this.state.isModalOpen.delete} toggle={()=>this.toggleModal('delete')} headerText={'Delete Cloud User'} bodyText={[<a className={'blue-link'} href={`mailto:${this.state.deleteEmailSelected}`}>{this.state.deleteEmailSelected}</a>, ` will lose access to all your portfolios and will not be able to login to MProfit Cloud. Are you sure you want to continue?`]} option1={{text:'No',onClick:()=>this.toggleModal('delete')}}  option2={{text:'Yes',onClick:()=>this.onDeleteEmail(this.state.deleteEmailSelected)}} loading={this.state.isLoading.delete}/>
            <AlertModal isOpen={this.state.isModalOpen.add} toggle={()=>this.toggleModal('add')} headerText={'Assign Cloud User'} bodyText={['Login instructions will be sent to ',<a className={"blue-link"} href={`mailto:${this.state.addEmailSelected}`}>{this.state.addEmailSelected}</a>, ` via email. Are you sure you want to continue?`]} option1={{text:'No',onClick:()=>this.toggleModal('add')}}  option2={{text:'Yes',onClick:()=>this.onAddEmail(this.state.addEmailSelected)}} loading={this.state.isLoading.add}/>
            <AlertModal isOpen={this.state.isModalOpen.error} toggle={()=>this.toggleModal('error')} headerText={'Email already in use'} bodyText={['The email ',<a className={"blue-link"} href={`mailto:${this.state.addEmailSelected}`}>{this.state.addEmailSelected}</a>, ` already exists with another account. Please enter another email to continue.`]} option2={{text:'Ok',onClick:()=>this.toggleModal('error')}}/>            
            <div className={styles.plan_section}>
                <div className="container-fluid">
                    <div className="row plan-grey-background">
                    <PlanSideBar isTemp={this.state.isTemp} page={"Users"}/>
                        <div className={["col-lg-10", "col-md-12", "right_section"].join(" ")}>
                            <div className="mpr_plan_section p-lg-5 p-md-3">
                                <div className={["mpr_c_plan_title", "mt-5", "pb-5"].join(" ")}>
                                    <h2>My users</h2>
                                </div>
                                {
                                    isCloud &&
                                    <div className={["card", "mpr-card-shadow", "p-4", "mb-md-5", "mb-3"].join(" ")}>
                                        <div className="col-12 mt-1 mb-1">
                                        If you have multiple user logins included in your plan, you can share login access with users. To get started, login to your MProfit account at <a href="https://cloud.mprofit.in" target="_blank">cloud.mprofit.in</a> and click on <strong>Tools</strong> {`>`} <strong>Access Control</strong>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.plan && !isCloud &&
                                    <CurrentPlanInformation planInformation={this.state.plan} showActionButtons={true} onActionClick={this.onActionClick} isUsersPage={true} regForCloud={this.state.regForCloud}/>
                                }
                                {
                                    !isCloud && this.state.cloudAddOnEmails.length>0 && 
                                    <>
                                    {
                                        (this.state.plan.IsExpired || this.state.cloudAddOnEmails.length == 1) ?
                                        <ProfileSectionHeading text={sectionHeadingText}/>                                        
                                        :
                                        <ProfileSectionHeading text={sectionHeadingText} icon={icon} onClick={this.onEditCloudAddOnToggle} useTextIcon={useTextIcon} iconText={iconText}/>                                        
                                    }
                                    <div className={["card", "mpr-card-shadow", "p-4", "mb-md-5", "mb-3"].join(" ")}>
                                        <div className="row">
                                            {
                                                this.state.cloudAddOnEmails.map((item,idx) => {
                                                    const fieldLabel = item.isAdmin ? (isCloud ? 'Primary User' : 'Primary Cloud User') : (isEditCloudEmailDisabled || item.disabled ? (isCloud ? 'Add-on User #' : 'Cloud Add-on User #')+idx : (isCloud ? 'Cloud user' : 'Assign a new Cloud user'))
                                                    return (
                                                        <FormField key={idx} fieldName={'email'} label={fieldLabel} sectionName={null} sectionValue={item} handleInputChange={(e)=>this.updateCloudEmail(e,idx)} perLine={1} disabled={item.disabled || isEditCloudEmailDisabled} showBtn={!isEditCloudEmailDisabled && !item.isAdmin} btnText={item.disabled ? 'Delete' : 'Add'} onBtnClick={()=>this.editEmail(item)} hideBtnIfError={true} dirty={this.state.dirty} inputType={'email'} customValidation={'email'} isLoaded={!this.state.isLoading.fetch}/>
                                                    )
                                                })
                                            }
                                            
                                        </div>
                                    </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        )
    }
}

export default ManageUsers