import React from "react"
import styles from "./styles.module.css"
import Tooltip from "../Tooltip";
import { getAdditionalServicesTooltipText } from "../../common/gFunctions";
import _Tooltip from "../Tooltip";
import { System_Type, AdditionalServicesOrderTooltip_Pro, AdditionalServicesOrderTooltip_Advisor } from "../../common/constants";
import { navigate } from "gatsby";

var dateFormat = require('dateformat');

class CurrentPlanInformation extends React.Component {

  render() {
    const { planInformation={}, showActionButtons=true, onActionClick, isUsersPage=false, regForCloud=false } = this.props;

    var numCloudActiveUsers = planInformation.NumCloudActiveUsers + (regForCloud ? 1 : 0);

    const expireText = planInformation.IsExpired ? 'Expired' : 'Expiring';

    const isCloud = planInformation.SystemType === System_Type.CLOUD;
    
    const systemType = !isCloud ? 'Desktop' : 'Cloud';
    const systemTypeText = `1 ${systemType} Base License`;

    const numAdditionalServices = planInformation.AdditionalServices ? planInformation.AdditionalServices.length : 0;
    var additonalServicesTooltipText;

    const isLegacyProduct = planInformation.IsLegacyProduct;
    const isLegacyToNewRenewalRequired = planInformation.IsLegacyToNewRenewalRequired;

    console.log(planInformation,'planInformation123');
    
    if (planInformation.AdditionalServices) {
      const isAdvisor = planInformation.ProductId > (isCloud ? 302 : 102);
      const isPro = planInformation.ProductId == (isCloud ? 302 : 102);
      if (numAdditionalServices > 0) {
        additonalServicesTooltipText = getAdditionalServicesTooltipText(planInformation.AdditionalServices, isAdvisor, isPro);
      } else {
        additonalServicesTooltipText = isAdvisor ? AdditionalServicesOrderTooltip_Advisor : AdditionalServicesOrderTooltip_Pro;
      }
    }

    const showAdditionalPacks = planInformation.SystemType == System_Type.DESKTOP ? planInformation.ProductId > 100 : planInformation.ProductId > 300;

    const openNewPlan = () => {
      navigate('/account/new-plan', {state: {isDesktop: isCloud !== true}})
    }

    return (
      <>
        <div className="mpr_cr_plan_info">
          <div className={["card", "mpr-card-shadow", styles.cardContainer, "mb-5"].join(" ")}>
            <div className="row">
              <div className={["col-md-3","col-xl-3", isCloud ? "col-12" :  "col-6","order-md-1",styles.mobileCardMargin, "order-1","d-flex flex-column", isLegacyProduct ? 'justify-content-between' : ''].join(" ")}>
                <label className={[].join(" ")}>My Current Plan </label>
                <div>
                <h5 className="mpr_cr_plan_title">{planInformation.ProductName}
                {
                  !planInformation.IsFree && planInformation.RenewalEligible &&
                  <span className="expiring"> {expireText}</span>
                }
                </h5>
                {
                  isLegacyProduct &&
                  <div className="mpr_product_type">{systemTypeText}</div>
                }
                {
                  showAdditionalPacks && isLegacyProduct &&
                  <div className="mpr_product_type">
                    {numAdditionalServices} Additional Pack{numAdditionalServices!==1?'s':''}
                    <span className="qcircle small-qcircle" id={'additional-services-tooltip'} data-toggle="tooltip" data-placement="right">?</span>
                    <_Tooltip text={additonalServicesTooltipText} header={"Additional packs in your plan:"} target={'additional-services-tooltip'}/>
                  </div>
                }
                </div>
              </div>
              {
                !planInformation.IsFree && 
                <>
                  { planInformation.NumDesktopUsers > 0 &&
                    <div className={["col-md-3","col-xl-2", "col-6","order-md-2",styles.mobileCardMargin, "order-3","d-flex flex-column justify-content-between"].join(" ")}>
                      <label>{isUsersPage ? 'Active Desktop Users' : 'Desktop add-ons'}</label> 
                      <div>
                      <h5>{isUsersPage ? planInformation.NumDesktopActiveUsers + '/' + planInformation.NumDesktopUsers : planInformation.NumDesktopAddOns}</h5>
                      <div className="mpr_product_type">&nbsp;</div>
                      {
                        showAdditionalPacks &&
                        <div className="mpr_product_type">&nbsp;</div>
                      }
                      </div>
                    </div>
                  }
                  {
                    planInformation.NumCloudUsers > 0 &&
                    <div className={["col-md-3","col-xl-2", "col-6","order-md-2",styles.mobileCardMargin,  isCloud ? "order-2" : "order-3","d-flex flex-column justify-content-between"].join(" ")}>
                      <label>{isUsersPage ? (isCloud ? 'Active Users' : 'Active Cloud Users') : (isLegacyProduct ? (isCloud ? 'Add-ons' : 'Cloud add-ons') : 'User Logins')}</label> 
                      <div>
                      <h5>{isUsersPage ? numCloudActiveUsers + '/' + planInformation.NumCloudUsers : (isLegacyProduct ? planInformation.NumCloudAddOns : planInformation.NumCloudUsers)}</h5>
                      <div className="mpr_product_type">&nbsp;</div>
                      {
                        showAdditionalPacks &&
                        <div className="mpr_product_type">&nbsp;</div>
                      }
                      </div>
                    </div>
                  }
                  {
                    !isLegacyProduct && !isUsersPage && planInformation.NumFamiliesAdditional > 0 &&
                    <div className={["col-md-3","col-xl-2", "col-6","order-md-2",styles.mobileCardMargin,  isCloud ? "order-2" : "order-3","d-flex flex-column justify-content-between"].join(" ")}>
                      <label>{'Additional Families'}</label> 
                      <div>
                      <h5>{planInformation.NumFamiliesAdditional}</h5>
                      <div className="mpr_product_type">&nbsp;</div>
                      {
                        showAdditionalPacks &&
                        <div className="mpr_product_type">&nbsp;</div>
                      }
                      </div>
                    </div>
                  }
                  <div className={["col-md-3", "col-xl-2","col-6","order-md-3",styles.mobileCardMargin,  isCloud ? "order-3" : "order-2","d-flex flex-column justify-content-between"].join(" ")}>
                    <label>Expiry Date</label> 
                    <div>
                    <h5>{dateFormat(new Date(planInformation.LicenseExpDt),'dd/mm/yyyy')}</h5>
                    <div className="mpr_product_type">&nbsp;</div>
                    {
                      showAdditionalPacks &&
                      <div className="mpr_product_type">&nbsp;</div>
                    }
                    </div>
                  </div>
                </>
              }
              {
                showActionButtons && !(isLegacyToNewRenewalRequired && !planInformation.RenewalEligible) &&
                <div className={["col-xl-3", "col-12","mt-3","mt-xl-0","order-md-4",styles.mobileActionButtonMargin, "order-4","d-flex flex-column justify-content-between","text-nowrap"].join(" ")}>
                    <label className={["d-none", "d-xl-block"].join(" ")}>Action</label>
                    {
                      isLegacyToNewRenewalRequired
                      ?
                      <div className={"border-lg-top"}>
                        <button className={["btn", "btn-primary", "btn-sm", "rounded", "mr-2"].join(" ")} id="upgradeBtn" onClick={()=>{openNewPlan()}}>Renew</button>
                        <div className="mpr_product_type">&nbsp;</div>
                      </div>
                      :
                      <div className={"border-lg-top"}>
                        <button className={["btn", "btn-primary", "btn-sm", "rounded", "mr-2"].join(" ")} id="upgradeBtn" onClick={()=>{onActionClick(true)}}>Upgrade</button>
                        {
                          !planInformation.IsFree && 
                          <>
                            <button id="renewalBtn" className={["btn", "btn-primary", "btn-sm", "rounded", !planInformation.RenewalEligible ? "disabled" : ""].join(" ")} onClick={() => {onActionClick(planInformation.RenewalEligible)}}>Renew</button>
                            {
                              !planInformation.RenewalEligible &&
                              <Tooltip text="To change your plan or buy more add-ons, click on the upgrade button." header="You can renew your license within 30 days of your expiry date." target="renewalBtn"/>
                            }
                          </>
                        }
                        {
                          showAdditionalPacks &&
                          <div className="mpr_product_type">&nbsp;</div>
                        }
                      </div>
                    }
                </div>
              }
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default CurrentPlanInformation